define('due-backoffice/models/survey', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // ReadOnly attrs
    ratings_background_color: (0, _attr.default)({ readOnly: true }),
    company: (0, _relationships.belongsTo)('company', { readOnly: true }),
    languages: (0, _relationships.hasMany)('languages', { readOnly: true }),

    // Editables attrs
    name: (0, _attr.default)(),
    status: (0, _attr.default)(),
    has_embedded_nps: (0, _attr.default)('boolean'),
    need_nps_confirmation: (0, _attr.default)('boolean'),
    can_edit_nps_rate: (0, _attr.default)('boolean'),
    welcome_text: (0, _attr.default)('string'),
    has_alternative_rating_scale: (0, _attr.default)('boolean'),
    auto_tagging_enabled: (0, _attr.default)('boolean'),
    client_unique_segment: (0, _attr.default)('string'),
    company_name: (0, _attr.default)('string', { readOnly: true }),
    untagged_count: (0, _attr.default)('number', { readOnly: true }),
    company_id: (0, _attr.default)({ readOnly: true }),
    cooldown: (0, _attr.default)('number'),
    gather_public_reviews: (0, _attr.default)('boolean'),
    hide_thanks: (0, _attr.default)('boolean'),
    no_logo: (0, _attr.default)('boolean'),
    hide_dispatch_statistics: (0, _attr.default)('boolean'),
    hide_open_question_numbering: (0, _attr.default)('boolean'),
    hide_all_questions_numbering: (0, _attr.default)('boolean'),
    closed_questions_after_recommendation: (0, _attr.default)('boolean'),
    closed_questions_after_comment: (0, _attr.default)('boolean'),
    has_custom_html: (0, _attr.default)('boolean'),
    gather_nps_open_questions: (0, _attr.default)('boolean'),
    is_v2: (0, _attr.default)('boolean', { readOnly: true }),
    enable_fake_data: (0, _attr.default)('boolean', { readOnly: true }),
    is_test_company: (0, _attr.default)('boolean', { readOnly: true }),
    custom_html: (0, _attr.default)(),
    tagging_start_date: (0, _attr.default)(),
    consent_texts: (0, _attr.default)(),
    mapping_table_key: (0, _attr.default)('string'),

    //Title
    custom_font_title: (0, _attr.default)('string'),
    custom_font_size_title: (0, _attr.default)('string'),
    custom_letter_spacing_title: (0, _attr.default)('string'),
    //Text
    custom_font_text: (0, _attr.default)('string'),
    custom_font_size_text: (0, _attr.default)('string'),
    custom_letter_spacing_text: (0, _attr.default)('string'),
    //Button
    custom_button_bg_color: (0, _attr.default)('string'),
    hide_button_shadow: (0, _attr.default)('boolean'),

    // RelationShips
    rating_scale: (0, _relationships.belongsTo)('rating-scale'),
    closed_questions_rating_scale: (0, _relationships.belongsTo)('rating-scale'),
    segment_pickers: (0, _relationships.hasMany)('segment-picker'),
    country_segment: (0, _relationships.belongsTo)('companies-segment'),
    store_segment: (0, _relationships.belongsTo)('companies-segment'),
    // Computeds
    status_icon: Ember.computed('status', function () {
      if (this.get('status')) {
        return this.get('status') + '_survey';
      }
    }),

    search_value: Ember.computed('name', 'company_name', function () {
      return this.get('company_name') + " | " + this.get('name');
    })

  });
});
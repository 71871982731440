define('due-backoffice/models/generate-feedback', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    survey_id: (0, _attr.default)('string'),
    number: (0, _attr.default)('number'),
    start_date: (0, _attr.default)('string'),
    end_date: (0, _attr.default)('string'),
    survey_type: (0, _attr.default)('string'),
    delivery_type: (0, _attr.default)('string')
  });
});
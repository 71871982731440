define("due-backoffice/translations/en", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "en": {
      "reporting_tagging": {
        "verbatim": "<p style='text-align: justify;'>Number of feedback hard_completed with comment<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "tagged": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were tagged with due-exclusive tags from the dashboard or BO<br>(% tagged / verbatims)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "tagged_from_tool": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were tagged from the BO<br>(% tagged from tools / verbatims)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>- Tagger DUE <br>",
        "well_assigned": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were tagged from the BO without any autotag deletion in the dashboard<br>(% well assigned / tagged from tools)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>- Tagger DUE <br>",
        "not_tagged": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were not tagged from the dashboard or BO<br>(% not tagged / verbatims)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "not_tagged_sla": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were not tagged from the dashboard or BO following SLA (72h)<br>(% not tagged SLA / verbatims)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "recall": "<p style='text-align: justify;'>(% well assigned / (well assigned + missed assigned))<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "missed_assigned": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were tagged with due-exclusive tags from the dashboard<br>(% missed assigned / tagged from tools)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>",
        "precision": "<p style='text-align: justify;'>(% well assigned / (well assigned + wrong assigned))<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>- Tagger DUE <br>",
        "wrong_assigned": "<p style='text-align: justify;'>Number of feedback hard_completed with comments that were tagged from the BO with autotag deletion in the dashboard<br>(% wrong assigned / tagged from tools)<br><br><u>Filtered by</u> : <br>- Date Range <br>- Survey <br>- Verbatim Languages <br>- Tagger DUE <br>",
        "first_tagged_at": "<p style='text-align: justify;'>The date of the first tag added from the dashboard or BO",
        "first_from_tool_tagged_at": "<p style='text-align: justify;'>The date of the first tag added from the BO only"
      }
    }
  };
});